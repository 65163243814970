.App {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  width: 100vw;
  height: 100vh;
}

.App-header {
  background-color: "transparent";
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-body {
  background-color: "transparent";
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}


@media (min-width: 650px) {
  .App {
    background-position: center center;
  }

  .App-content {
    align-items: flex-end;
    justify-content: center;
  }
}
